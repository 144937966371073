<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <h1 class="h1 text-center margin-b30">
          智能终端设备及应用软件
          <br>
          地震预警服务规范
        </h1>
        <h2 class="h2 margin-t30 margin-b20 nav-title" id="0">
          引言
        </h2>
        <p class="p margin-b10">
          中国地震活动频度高、强度大、震源浅、分布广，是一个地震灾害严重的国家。目前地震预报还是世界难题，且短期内还难以攻克。地震预警是目前减少地震灾害造成的人员伤亡和财产损失的有效方式之一。
        </p>
        <p class="p margin-b10">
          地震预警指的是地震发生时，当监测网内的传感器监测到地震波，利用电波比地震波快的原理，在破坏性地震波到达之前提前给用户发送预警信息。地震预警应用的关键之一在于如何快速准确地将预警信息触达给用户。在智能设备全面普及的今天，智能终端设备是非常重要的地震预警信息触达用户的渠道。通过在智能终端操作系统或应用软件预置地震预警功能，可以提高触达率，提升用户体验，进一步加强地震预警成果的应用，产生更好的减灾效益、社会效益、民生效益和科技效益。本标准旨在规范智能终端设备及应用软件提供地震预警功能的安全性、稳定性以及产品体验等要求，规范各设备生产企业及服务提供方遵循要求提供地震预警服务，保证地震预警服务统一的用户体验。
        </p>
        <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
          1 &nbsp; 范围
        </h2>
        <p class="p margin-b10">
          本标准旨在规范智能终端设备及应用软件提供地震预警功能的安全性、稳定性以及产品体验等。
        </p>
        <p class="p margin-b10">
          本标准适用于移动智能终端设备生产企业及应用软件提供方，个别条款不适合用于特殊行业，其他终端也可参考使用。
        </p>
        <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
          2 &nbsp; 规范性引用文件
        </h2>
        <p class="p margin-b10">
          下列文件对于本文件的应用是必不可少的。凡是注日期的引用文件，仅所注日期的版本适用于本文件。凡是不注日期的引用文件，其最新版本（包括所有的修改单）适用于本文件。
        </p>
        <p class="p margin-b10 padding-l30">
          GB/T 18207.1-2008 防震减灾术语 第1部分：基本术语
        </p>
        <p class="p margin-b30 padding-l30">
          GB 17740-1999 地震震级的规定
        </p>
        <h2 class="h2 margin-t30 margin-b30 nav-title" id="3">
          3 &nbsp; 术语、定义和缩略语
        </h2>
        <h3 class="h3 margin-t30 margin-b20 nav-title" id="3-1">
          3.1 术语和定义
        </h3>
        <p class="p margin-b30">
        下列术语和定义适用于本文件。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-1">
          3.1.1 &nbsp; 地震预警
        </h4>
        <p class="p margin-b20  padding-l30">
          利用地震发生时，电波比地震波快的原理，在破坏性地震波到达之前提前给用户发送预警信息。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-2">
          3.1.2 &nbsp; 地震震级
        </h4>
        <p class="p margin-b20  padding-l30">
          地震震级是通过仪器监测分析给出地震大小的一种量度，是衡量地震本身大小的相对量度，由地震所释放出来的能量大小来决定。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-3">
          3.1.3 &nbsp; 地震预估烈度
        </h4>
        <p class="p margin-b20  padding-l30">
          地震对用户所在区域可能造成的影响的强弱程度，是计算烈度值，表征了地震导致的影响程度或晃动程度。同一次地震，在不同的地方造成的破坏不同。烈度与震级、震源深度、震中距离等有关。一般来讲，一次地震发生后，震中区的破坏最重，烈度最高，称为震中烈度。从震中向四周扩展，地震烈度逐渐减小。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-4">
          3.1.4 &nbsp; 预警时间
        </h4>
        <p class="p margin-b20  padding-l30">
          用户接收到地震预警信息时，破坏性地震波到达用户所在区域的剩余时间，一般以秒为单位计。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-5">
          3.1.5 &nbsp; 智能终端
        </h4>
        <p class="p margin-b20  padding-l30">
          能够接入互联网，具有能够提供应用软件开发接口的开放操作系统，并能够安装和运行应用软件的终端，包括但不限于智能移动终端、智能电视、IOT设备等。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-6">
          3.1.6 &nbsp; 智能操作系统
        </h4>
        <p class="p margin-b20  padding-l30">
          智能终端最基本的系统软件，它控制和管理智能终端各种硬件和软件资源，并提供应用软件开发接口。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-7">
          3.1.7 &nbsp; 应用软件
        </h4>
        <p class="p margin-b20  padding-l30">
          在智能操作系统基础上利用程序设计语言编制的程序集合，供多用户使用。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-8">
          3.1.8 &nbsp; 地震预警强提醒模式
        </h4>
        <p class="p margin-b20  padding-l30">
          当智能终端设备、应用软件收到地震预警信息时，对有明显震感的地震，智能终端设备或应用软件应采用强提醒模式。强提醒模式下应有明显的报警声音，智能终端设备或应用软件应播报地震波到达用户所在区域的剩余时间。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="3-1-9">
          3.1.9 &nbsp; 地震预警信息展示模式
        </h4>
        <p class="p margin-b10  padding-l30">
          当智能终端设备、应用软件收到地震预警信息时，对没有明显震感的地震，智能终端设备或应用软件可以根据用户需要采用地震预警信息展示模式。信息展示模式下不应有报警声音，智能终端或应用软件通过地震预警提示音来提示或可根据自身特点参考一般消息通知的形式展示地震预警信息。
        </p>
        <h2 class="h2 margin-t30 margin-b30 nav-title" id="4">
          4 &nbsp; 地震预警信息传递链路
        </h2>
        <p class="p margin-b10 ">
          地震预警信息传递链路如下图所示：
        </p>
        <p class="margin-b10">
          <img width="500" src="@/assets/img/documents/6E5CF433-26F8-4633-AC61-8545D41ABE77.png" alt="">
        </p>
        <p class="p margin-b10 ">
          地震预警网在监测到地震发生时，通过API接口等方式将预警信息发送给智能终端厂商或应用软件开发者的服务器，服务器根据预警信息中受地震影响范围筛选出需要推送的用户，然后使用推送等通道将预警信息发送到用户的智能终端设备或应用软件客户端，智能终端设备或应用软件客户端再根据设备及软件特性将预警信息展示给用户。
        </p>
        <p class="p margin-b10 ">
          智能终端或应用软件根据终端当前位置和震中位置等地震信息，依据相关算法计算后得到的预警时间、预估烈度。对于有定位功能的智能终端设备应以当前位置来计算预警时间、预估烈度，没有定位功能的智能终端可以使用IP地址解析计算预警时间、预估烈度。
        </p>
        <h2 class="h2 margin-t30 margin-b30 nav-title" id="5">
          5 &nbsp; 地震预警产品的技术要求
        </h2>
        <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-1">
          5.1 地震预警网
        </h3>
        <p class="p margin-b20 ">
          地震预警网提供的预警信息应当及时准确，满足地震预警产品要求。预警信息中至少包括：预警编号、预警更新序号、发震时刻、震中位置（含经纬度）、预警震级、地震受影响区域、预警信息来源等。
        </p>
        <h3 class="h3 margin-t30 margin-b20 nav-title" id="5-2">
          5.2 智能终端设备厂商及应用软件开发者
        </h3>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="5-2-1">
          5.2.1 &nbsp; 性能
        </h4>
        <p class="p margin-b20 padding-l30">
          地震预警服务的本质是在跟地震波赛跑，所以对预警信息处理性能有非常高的要求，一般要求智能终端设备厂商或应用软件开发者在收到预警信号尽可能快的将预警信息推送到向受影响区域的用户。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="5-2-2">
          5.2.2 &nbsp;  稳定性
        </h4>
        <p class="p margin-b10 padding-l30">
          地震具有突发性，目前还无法对地震进行准确预报，所以需要保证地震预警服务实时在线，智能终端设备厂商服务器或应用软件开发者服务器需要采用多机器多机房混部机制，确保预警服务器有足够的容灾备份能力。
        </p>
        <p class="p margin-b10 padding-l30">
          智能终端设备厂商和应用软件开发者应该具备自动化监测和预警机构联通状态、服务器状态、定期检测推送下发通道状态的能力，并有内部通知机制以及与预警机构的沟通机制，确保问题得到及时修复。
        </p>
        <h4 class="h4 margin-t20 margin-b20  padding-l30 nav-title" id="5-2-3">
          5.2.3 &nbsp;  安全性
        </h4>
        <h5 class="h5 margin-t20 margin-b10  padding-l30 nav-title" id="5-2-3-1">
          5.2.3.1 &nbsp;  安全管理要求
        </h5>
        <p class="p-small margin-b10 padding-l30">
          地震预警信息非常特殊，因测试等原因导致的信息误发会造成非常严重的后果，所以需要智能终端设备厂商和应用软件开发者在代码提交、上线部署、服务器授权登录、推送秘钥管理等各个环节加强管控，每个环节至少双人交叉检查，同时需有审批、授权机制。
        </p>
        <p class="p-small margin-b10 padding-l30">
          严厉禁止在正式环境进行地震预警测试。
        </p>
        <p class="p-small margin-b20 padding-l30">
          应对正式环境账号信息进行安全性管理，只能由获得授权的专人直接对接。
        </p><h5 class="h5 margin-t20 margin-b10  padding-l30 nav-title" id="5-2-3-2">
          5.2.3.2 &nbsp;  技术措施
        </h5>
        <p class="p-small margin-b10 padding-l30">
          地震预警具有强时效性，智能终端设备或应用软件在收到预警信息后应对预警信息的发送时间进行验证，当前时间与发震时刻时间间隔超过５分钟，就不应该再以预警形式显示该信息。
        </p>
        <p class="p-small margin-b20 padding-l30">
          智能终端设备厂商或应用软件开发者应该具备验证预警信息来源的能力，需根据预警信息的发震时刻、震中位置、预警震级等字段进行签名，智能终端或应用软件在收到预警信息后对签名进行验证，防止预警信息被篡改。
        </p>
        <h2 class="h2 margin-b30  margin-t30 nav-title" id="6">
          6 &nbsp;  地震预警产品的用户交互
        </h2>
        <h3 class="h3 margin-t30 margin-b20 nav-title" id="6-1">
          6.1 总体要求
        </h3>
        <h4 class="h4 margin-t30 margin-b10  padding-l30 nav-title" id="6-1-1">
          6.1.1 &nbsp;  开启
        </h4>
        <p class="p margin-b10 padding-l30">
          在现阶段部分用户对地震预警了解不充分的情况下，可采用用户主动开启地震预警功能的方式。需要保障开启入口易发现、易查询，可使用全局搜索或语音助理的方式跳转到入口，方便用户找到功能入口。在地震多发区域、特殊场景等，建议推荐用户开启地震预警功能。
        </p>
        <p class="p margin-b10 padding-l30">
          在开启时给用户演示地震预警形式，提供防震减灾相关知识的科普学习，提升用户的防灾减灾意识和能力。
        </p>
        <p class="p margin-b20 padding-l30">
          建议厂商主动进行地震预警科普，提升用户防震减灾意识和能力，引导更多用户开启地震预警功能。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="6-1-2">
          6.1.2 &nbsp;  展示内容
        </h4>
        <p class="p margin-b20 padding-l30">
          智能终端设备、应用软件应根据设备或软件特性在推送地震预警信息时给用户提供发震时刻、震中位置、预警震级、预估烈度、预警时间以及预警信息来源、技术支持方等信息。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="6-1-3">
          6.1.3 &nbsp;  预警模式
        </h4>
        <p class="p margin-b20 padding-l30">
          建议地震预警信息优先在预警机构确定的受影响区域推送，智能终端、应用软件应根据用户当前位置结合地震预警信息计算预估烈度，根据预估烈度确定合适的地震预警提示模式。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="6-1-4">
          6.1.4 &nbsp;  报警音
        </h4>
        <p class="p margin-b20 padding-l30">
          地震预警强提醒模式应该有报警音，在地震横波到来之前警报音以“嘀”声作为地震影响提示音并配合地震横波到达时间的倒计时，当地震横波到达时响“呜呜”声。
        </p>
        <div class="padding-l30 margin-b20">
          <p class="p margin-b10">不同地震影响程度对应的声音提示：</p>
          <table class="p" cellspacing="0" cellpadding="0">
            <tr>
              <td>
                <p>地震影响程度</p>
              </td>
              <td >
                <p>声音提示方式</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>震感轻微</p>
              </td>
              <td >
                <p>仅倒计时</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>震感较强</p>
              </td>
              <td >
                <p>倒计时+嘀</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>震感强烈</p>
              </td>
              <td >
                <p>倒计时+嘀嘀</p>
              </td>
            </tr>
          </table>
        </div>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="6-1-5">
          6.1.5 &nbsp;  信息更新
        </h4>
        <p class="p margin-b20 padding-l30">
          随着地震波的传播，预警机构在发出地震预警信息后，后续可能还会对该预警信息进行修正，所以对预警信息的更新策略为：
        </p>
        <ul class="padding-l40">
          <li >
            <p class="p margin-b10">
              信息更新：智能终端设备厂商或应用软件开发者需要接收更新的地震预警信息，对相同预警编号的预警信息进行更新。
            </p>
          </li>
          <li>
            <p class="p margin-b10">
              预警模式：以同一地震收到预警信息里面地震等级最高的地震等级决定；若某一报信息触发了强提醒预警模式，后续的更新报需要一直保持强提醒模式。
            </p>
          </li>
        </ul>
        <h3 class="h3 margin-t30 margin-b20  nav-title" id="6-2">
          6.2 &nbsp;  智能移动终端和应用软件
        </h3>
        <p class="p margin-b20">
          智能终端或应用软件获取或计算出预估烈度和预警时间，然后依据烈度等级给用户不同的展示形态。
        </p>
        <h4 class="h4 margin-t20 margin-b10  padding-l30 nav-title" id="6-2-1">
          6.2.1 &nbsp;  地震预警强提醒模式
        </h4>
        <p class="p margin-b10 padding-l30">
          主要展示对用户所在区域影响较大的地震（如震感强烈）。该类地震预警是用户需要立刻关注的信息，是地震预警发挥减灾作用的主要途径，所以要用强提醒的模式来展示地震预警信息，并对不同的震感强度，予以不同颜色提示，帮助用户迅速判断影响情况、合理避险。
        </p>
        <div class="padding-l30 margin-b30">
          <table class="p" cellspacing="0" cellpadding="0">
            <tr>
              <td>
                <p>展示形式</p>
              </td>
              <td >
                <p>全屏覆盖，展示窗口的背景颜色应根据地震影响程度区分，<br>方便用户在第一时间能区分出受地震影响的严重程度</p>
                <p>震感轻微：展示窗口背景色为黄色</p>
                <p>震感较强：展示窗口背景色为橙色</p>
                <p>震感强烈：展示窗口背景色为红色</p>
                <p>色值可跟随各系统色彩风格设定，以易于用户区分为原则。</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>展示内容</p>
              </td>
              <td >
                <p>应包括地震波到达的倒计时、震中位置、预警震级、预估烈度、震感强弱等信息</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否全屏显示</p>
              </td>
              <td >
                <p>是</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否有报警音</p>
              </td>
              <td >
                <p>是</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否有震动</p>
              </td>
              <td >
                <p>建议有，作为特殊情况的补偿</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否显示倒计时</p>
              </td>
              <td >
                <p>是，倒计时需要醒目展示且动态递减，并播报倒计时</p>
              </td>
            </tr>
          </table>
        </div>
        <h4 class="h4 margin-t20 margin-b20  padding-l30 nav-title" id="6-2-2">
          6.2.2 &nbsp;  同一台设备存在多个地震预警服务的情况
        </h4>
        <p class="p margin-b20 padding-l30">
          当智能终端和应用软件同时收到预警时，建议系统内置软件提醒展示优先级高于第三方软件提醒。
        </p>
        <h3 class="h3 margin-t20 margin-b20 nav-title" id="7">
          资料性附录：
        </h3>
        <h4 class="h4 margin-t20 margin-b20  padding-l30 nav-title" id="7-1">
          地震预警图示：
        </h4>
        <h5 class="h5 margin-t20 margin-b20  padding-l30 nav-title" id="7-1-1">
          震感轻微：
        </h5>
        <p class=" margin-b20 padding-l30">
          <el-image 
            :src="require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.006.png')" 
            :preview-src-list="[require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.006.png')]">
          </el-image>
        </p>
        <h5 class="h5 margin-t20 margin-b20  padding-l30 nav-title" id="7-1-2">
          震感较强：
        </h5>
        <p class=" margin-b20 padding-l30">
          <el-image 
            :src="require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.007.png')" 
            :preview-src-list="[require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.007.png')]">
          </el-image>
        </p>
        <h5 class="h5 margin-t20 margin-b20  padding-l30 nav-title" id="7-1-3">
          震感强烈：
        </h5>
        <p class=" margin-b30 padding-l30">
          <el-image 
            :src="require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.008.png')" 
            :preview-src-list="[require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.008.png')]">
          </el-image>
        </p>
        <h3 class="h3 margin-t30 margin-b20  nav-title" id="7-2">
          地震预警信息展示模式
        </h3>
        <p class="p  margin-b20">
          主要展示对用户当前所在区域影响小的地震（如可能有震感），预警信息以普通通知的方式展示在智能移动终端或应用软件上，用户可以选择关闭此类信息的接收。
        </p>
        <div class="padding-l30 margin-b30 ">
          <table class="p" cellspacing="0" cellpadding="0">
            <tr>
              <td>
                <p>是否必须</p>
              </td>
              <td >
                <p>否，根据各终端或应用自身需求以及用户需求而定</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>展示形式</p>
              </td>
              <td >
                <p>通知</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>展示内容</p>
              </td>
              <td >
                <p>应包括震中位置、地震等级(或预估烈度）、发震时刻等信息。</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否全屏显示</p>
              </td>
              <td >
                <p>否</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否有报警音</p>
              </td>
              <td >
                <p>否，可通过地震预警信息提示音来提示，也可根据自身特点参考普通消息通知的形式提示地震预警信息。</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否有震动</p>
              </td>
              <td >
                <p>否</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>是否显示倒计时</p>
              </td>
              <td >
                <p>否</p>
              </td>
            </tr>
          </table>
        </div>
        <p class="p  margin-b20">
          示意图如下：
        </p>
        <p class=" margin-b20 padding-l30">
          <el-image 
            :src="require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.009.png')" 
            :preview-src-list="[require('@/assets/img/documents/0bbb9bbc-4361-49f1-9a32-32a16fb6f5e6.009.png')]">
          </el-image>
        </p>

      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 地震预警服务规范',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,金标认证,地震预警服务规范'
      },
      {
        name: 'description',
        content: 'Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "引言",
            isActive:0,
            class:'#0',
          },
          {
            name: "一、 范围",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 规范性引用文件",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语、定义和缩略语",
            isActive:null,
            class:'#3',
            child:[
              {
                name: "3.1 术语和定义",
                isActive:null,
                class:'#3-1',
                child:[
                  {
                    name: "3.1.1 地震预警",
                    isActive:null,
                    class:'#3-1-1',
                  },
                  {
                    name: "3.1.2 地震震级",
                    isActive:null,
                    class:'#3-1-2',
                  },
                  {
                    name: "3.1.3 地震预估烈度",
                    isActive:null,
                    class:'#3-1-3',
                  },
                  {
                    name: "3.1.4 预警时间",
                    isActive:null,
                    class:'#3-1-4',
                  },
                  {
                    name: "3.1.5 智能终端",
                    isActive:null,
                    class:'#3-1-5',
                  },
                  {
                    name: "3.1.6 智能操作系统",
                    isActive:null,
                    class:'#3-1-6',
                  },
                  {
                    name: "3.1.7 应用软件",
                    isActive:null,
                    class:'#3-1-7',
                  },
                  {
                    name: "3.1.8 地震预警强提醒模式",
                    isActive:null,
                    class:'#3-1-8',
                  },
                  {
                    name: "3.1.9 地震预警信息展示模式",
                    isActive:null,
                    class:'#3-1-9',
                  },
                ],
              },
            ],
          },
          {
            name: "四、 地震预警信息传递链路",
            isActive:null,
            class:'#4',
          },
          {
            name: "五、 地震预警产品的技术要求",
            isActive:null,
            class:'#5',
            child:[
              {
                name: "5.1 地震预警网",
                isActive:null,
                class:'#5-1',
              },
              {
                name: "5.2 智能终端设备厂商及应用软件开发者",
                isActive:null,
                class:'#5-2',
                child:[
                  {
                    name: "5.2.1 性能",
                    isActive:null,
                    class:'#5-2-1',
                  },
                  {
                    name: "5.2.2 稳定性",
                    isActive:null,
                    class:'#5-2-2',
                  },
                  {
                    name: "5.2.3 安全性",
                    isActive:null,
                    class:'#5-2-3',
                    child:[
                      {
                        name: "5.2.3.1 安全管理要求",
                        isActive:null,
                        class:'#5-2-3-1',
                      },
                      {
                        name: "5.2.3.2 技术措施",
                        isActive:null,
                        class:'#5-2-3-2',
                      },
                    ]
                  },
                ],
              },
            ],
          },
          {
            name: "六、 地震预警产品的用户交互",
            isActive:null,
            class:'#6',
            child:[
              {
                name: "6.1 总体要求",
                isActive:null,
                class:'#6-1',
                child:[
                  {
                    name: "6.1.1 开启",
                    isActive:null,
                    class:'#6-1-1',
                  },
                  {
                    name: "6.1.2 展示内容",
                    isActive:null,
                    class:'#6-1-2',
                  },
                  {
                    name: "6.1.3 预警模式",
                    isActive:null,
                    class:'#6-1-3',
                  },
                  {
                    name: "6.1.4 报警音",
                    isActive:null,
                    class:'#6-1-4',
                  },
                  {
                    name: "6.1.5 信息更新",
                    isActive:null,
                    class:'#6-1-5',
                  },
                ],
              },
              {
                name: "6.2 智能移动终端和应用软件",
                isActive:null,
                class:'#6-2',
                child:[
                  {
                    name: "6.2.1 地震预警强提醒模式",
                    isActive:null,
                    class:'#6-2-1',
                  },
                  {
                    name: "6.2.2 同一台设备存在多个地震预警服务的情况",
                    isActive:null,
                    class:'#6-2-2',
                  },
                ],
              },
            ],
          },
          {
            name: "资料性附录",
            isActive:null,
            class:'#7',
            child:[
              {
                name: "地震预警图示",
                isActive:null,
                class:'#7-1',
                child:[
                  {
                    name: "震感轻微",
                    isActive:null,
                    class:'#7-1-1',
                  },
                  {
                    name: "震感较强",
                    isActive:null,
                    class:'#7-1-2',
                  },
                  {
                    name: "震感强烈",
                    isActive:null,
                    class:'#7-1-3',
                  },
                ]
              },
              {
                name: "地震预警信息展示模式",
                isActive:null,
                class:'#7-2',
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">
.h1{
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2{
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3{
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4{
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5{
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p{
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small{
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
table{
  border-collapse:collapse;
  th,td{
    border:1px solid #000;
    padding: 10px;
  }
}
ul{
  li{
    list-style: disc;
  }
}

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
